<template>
  <div class="filter-menu-dropdown card">
    <header class="card-header">
      <p class="card-header-title has-text-info">
        <span class="icon">
          <i :class="`fal ${property.icon}`" />
        </span>
        <span>{{ property.label }}</span>
      </p>
      <a class="card-header-icon" aria-label="close" @click="clearMenu">
        Close
      </a>
    </header>
    <hr class="hr is-marginless" />
    <div class="card-content">
      <div class="columns is-multiline" v-if="hasAggs">
        <div
          class="column is-half-desktop is-full-tablet radio-tick"
          v-for="(agg, index) in aggs"
          :key="index"
        >
          <checkbox
            name="values"
            v-model="values"
            :value="agg.label"
            :disabled="loading"
          >
            <span class="is-capitalized">{{ agg.label }}</span>
          </checkbox>
        </div>
      </div>
      <p class="has-text-grey-light" v-else>No filter options available</p>
    </div>
    <footer class="card-footer">
      <div class="column is-12">
        <div class="columns">
          <div class="column">
            <a class="has-text-danger" v-if="hasSelected" @click="clearFilter"
              >Reset</a
            >
            <label class="has-text-grey-light" v-else>Reset</label>
          </div>
          <div class="column is-narrow">
            <p v-if="itemCount">{{ itemCount }} items selected</p>
            <label class="has-text-grey-light" v-else>No items selected</label>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { includes } from 'lodash'
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  name: 'SimilarListingsCheckboxFilter',
  props: {
    property: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      colourAggs: 'companion/similarListings/filterBy/colour',
      colourFilter: 'companion/similarListings/filterBy/colourFilter',
      listingAggs: 'companion/similarListings/filterBy/listing',
      listingFilter: 'companion/similarListings/filterBy/listingFilter',
      dealerAggs: 'companion/similarListings/filterBy/dealer',
      dealerFilter: 'companion/similarListings/filterBy/dealerFilter',
      loading: 'companion/similarListings/loading'
    }),
    values: {
      get() {
        return this.filter
      },
      set(values) {
        this.setFilter({ [this.type]: values })
      }
    },
    aggs() {
      return this[`${this.type}Aggs`]
    },
    filter() {
      return this[`${this.type}Filter`]
    },
    itemCount() {
      return this.values.length
    },
    hasSelected() {
      return this.itemCount
    },
    hasAggs() {
      return this.aggs && this.aggs.length > 0
    },
    type() {
      return this.property.type
    }
  },
  methods: {
    isSelected(key) {
      return includes(this.values, key) || !this.hasSelected
    },
    clearFilter() {
      this.setFilter({ [this.type]: [] })
    },
    ...mapActions({
      setFilter: 'companion/similarListings/filterBy/updateFilter'
    }),
    ...mapMutations({
      clearMenu: 'companion/similarListings/filterBy/clearMenu'
    })
  }
}
</script>
